import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { CMSSelectedMarina } from '@dm-workspace/types';
import { NcpMarinasService } from '@dm-workspace/new-customer-portal/ncp-layout';

@Component({
  selector: 'dm-ncp-ui-marina-info',
  templateUrl: 'ncp-ui-marina-info.component.html',
  styleUrl: 'ncp-ui-marina-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NcpUiMarinaInfoComponent {
  marina = input.required<CMSSelectedMarina>();
  marinaCover = computed(() => NcpMarinasService.getMarinaCoverUrl(this.marina()));
}
