import { Component, Input } from '@angular/core';
import { CmsContent } from '@dm-workspace/types';

@Component({
  selector: 'dm-ncp-cms-child',
  templateUrl: './ncp-cms-child.component.html',
  styleUrls: ['./ncp-cms-child.component.scss'],
})
export class NcpCmsChildComponent {
  @Input() public data: CmsContent[];
}
