<h3 class="text-header-md-m fw-500 mb-4 body-line-height">{{ "WE_ALREADY_HAVE_THIS_CUSTOMER" | translate | uppercase }}</h3>
<span class="text-secondary-strong fw-400 body-line-height text-body-l" [innerHTML]="'WE_ALREADY_HAVE_THIS_CUSTOMER_DESCRIPTION' | translate "></span>

<dm-form-radio-group class="row m-0 mt-4 user-boats" [(ngModel)]="selectedOption">
  <dm-form-radio-card name="GOTOLIST" [value]="ExistingCustomerOptions.LOGIN" [id]="'LOGIN'" class="border-0 py-2 px-0">
    <div class="d-flex flex-column">
      <span class="fw-700 text-body-s">{{ "LOGIN_TO_ACCOUNT" | translate | uppercase }}</span>
    </div>
  </dm-form-radio-card>

  <dm-form-radio-card name="GOTOLIST" [value]="ExistingCustomerOptions.EDIT_DATA" [id]="'EDIT_DATA'" class="border-0 py-2 px-0">
    <div class="d-flex flex-column">
      <span class="fw-700 text-body-s">{{ "EDIT_DATA" | translate | uppercase }}</span>
    </div>
  </dm-form-radio-card>

  <dm-form-radio-card name="GOTOLIST" [value]="ExistingCustomerOptions.SENT_ENQUIRY" [id]="'SENT_ENQUIRY'" class="border-0 py-2 px-0">
    <div class="d-flex flex-column">
      <span class="fw-700 text-body-s">{{ "SENT_ENQUIRY" | translate | uppercase }}</span>
    </div>
  </dm-form-radio-card>

  <dm-ui-horizontal-line />
  <div class="d-flex justify-content-end mt-3 p-0">
    <button type="submit" class="btn btn-primary w-auto" (click)="onConfirmOption()">
      {{ "CONFIRM" | translate }}
    </button>
  </div>
</dm-form-radio-group>
