import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export enum ExistingCustomerOptions {
  LOGIN = 'login',
  EDIT_DATA = 'editData',
  SENT_ENQUIRY = 'sentEnquiry',
}

@Component({
  selector: 'dm-ncp-booking-ncp-booking-summary-user-exist',
  templateUrl: './ncp-booking-summary-user-exist.component.html',
  styleUrl: './ncp-booking-summary-user-exist.component.scss',
})
export class NcpBookingSummaryUserExistComponent {
  selectedOption: ExistingCustomerOptions = ExistingCustomerOptions.LOGIN;
  #aModal = inject(NgbActiveModal);

  onConfirmOption(): void {
    this.#aModal.close(this.selectedOption);
  }

  protected readonly ExistingCustomerOptions = ExistingCustomerOptions;
}
