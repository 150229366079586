import { ChangeDetectionStrategy, Component, EventEmitter, input, Output, ViewEncapsulation } from '@angular/core';
import { CMSSelectedMarina } from '@dm-workspace/types';
import { Breakpoint } from '@dm-workspace/core';

@Component({
  selector: 'dm-ncp-ui-offer-summary',
  templateUrl: 'ncp-offer-summary.component.html',
  styleUrl: 'ncp-offer-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NcpUiOfferSummaryComponent {
  marina = input.required<CMSSelectedMarina>();
  protected readonly Breakpoint = Breakpoint;
  @Output()
  submitButton = new EventEmitter();
  disableButton = input(false);
  buttonLabel = input<string>();
}
