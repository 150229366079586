<div class="card">
  <div class="row card-body p-0 m-0">
    <div class="col-sm-6 p-0">
      <div class="card-text-wrapper px-4 py-2">
        <div class="d-flex my-2 align-items-center">
          <img
            [src]="marina().marinaOB.countryCode | flag"
            alt="{{ marina().marinaOB.name }}"
            class="me-2 flag vertical-align-middle"
          />
          <span class="fw-600 ml-2 country-name text-body-s">{{
            marina().marinaOB.countryCode | countryName | uppercase
          }}</span>
        </div>
        <h5 class="card-title fw-500 my-3">{{ marina().marinaOB.name }}</h5>
        <dm-ncp-marina-reviews
          *ngIf="marina().marinaCMS.attributes?.googleReviews"
          [rating]="marina().marinaCMS.attributes.googleReviews.rating"
          [userTotal]="marina().marinaCMS.attributes.googleReviews.user_ratings_total"
        />
        <p class="text-secondary mt-3 text-body-s fw-500">
          {{ "BERTH_LENGTH" | translate }}: {{ marina().marinaCMS.attributes.berthSettings.maxBoatLength }}m
        </p>
      </div>
    </div>
    <img class="col-sm-6 img-fluid h-auto p-0" [src]="marinaCover()" alt="berth-img" />
  </div>
</div>
