import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NcpUiRouteStepperComponent } from './components/ncp-ui-route-stepper/ncp-ui-route-stepper.component';
import { NcpUiRouteStepperItemComponent } from './components/ncp-ui-route-stepper-item/ncp-ui-route-stepper-item.component';
import { NcpUiRouteStepperCounterComponent } from './components/ncp-ui-route-stepper-counter/ncp-ui-route-stepper-counter.component';
import { LibsSharedModule } from '@dm-workspace/shared';
import { UiModule } from '@dm-workspace/ui';
import { NcpSharedModule } from '@dm-workspace/new-customer-portal/ncp-shared';
import { NcpUiOfferSummaryComponent } from './components/ncp-ui-offer-summary/ncp-offer-summary.component';
import { NcpUiMarinaInfoComponent } from './components/ncp-ui-marina-info/ncp-ui-marina-info.component';

const declarations = [
  NcpUiOfferSummaryComponent,
  NcpUiRouteStepperComponent,
  NcpUiMarinaInfoComponent,
  NcpUiRouteStepperItemComponent,
  NcpUiRouteStepperCounterComponent,
];

@NgModule({
  declarations: [...declarations],
  exports: [...declarations],
  imports: [CommonModule, TranslateModule, RouterOutlet, RouterLink, UiModule, LibsSharedModule, NcpSharedModule],
})
export class NcpUiModule {}
