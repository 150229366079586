import { Route } from '@angular/router';
import { NcpLayoutMainViewComponent } from '../../../../libs/new-customer-portal/ncp-layout/src/lib/views/ncp-layout-main-view/ncp-layout-main-view.component';

export const ncpAppRoutes: Route[] = [
  {
    path: 'quotes',
    loadChildren: () => import('@dm-workspace/new-customer-portal/ncp-quotes').then((m) => m.NcpQuotesModule),
  },
  {
    path: '',
    component: NcpLayoutMainViewComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () =>
          import('@dm-workspace/new-customer-portal/ncp-auth-route').then((m) => m.NcpAuthRouteModule),
      },
      {
        path: 'login',
        redirectTo: 'auth/login',
      },
      {
        path: 'new-booking',
        redirectTo: 'new',
      },
      {
        path: 'new',
        loadChildren: () => import('@dm-workspace/new-customer-portal/ncp-booking').then((m) => m.NcpBookingModule),
      },
      {
        path: 'confirmation',
        loadChildren: () =>
          import('@dm-workspace/new-customer-portal/ncp-confirmation').then((m) => m.NcpConfirmationModule),
      },

      {
        path: '**',
        redirectTo: 'new',
      },
    ],
  },

  {
    path: '**',
    redirectTo: '404',
  },
];
