<section class="summary-order d-flex flex-column">
  @if (Breakpoint.DESKTOP | minBreakpoint | async) {
    <h2 class="mb-4 text-header-xs text-header-lg-s">{{ "ORDER_SUMMARY" | translate | uppercase }}</h2>
    <dm-ncp-ui-marina-info [marina]="marina()" />
  } @else {
    <div class="d-flex align-items-center">
      <img
        [src]="marina().marinaOB.countryCode | flag"
        alt="{{ marina().marinaOB.name }}"
        class="me-2 flag vertical-align-middle"
      />
      <span class="fw-500 country-name__mobile me-2">{{ marina().marinaOB.name | uppercase }}</span>
    </div>
  }
  <ng-content />
</section>
@if (buttonLabel()) {
  <button class="btn btn-primary w-100" (click)="submitButton.emit()" [disabled]="disableButton()">
    {{ buttonLabel() }}
  </button>
}
