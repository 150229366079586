import { Component, Input } from '@angular/core';
import { QuoteDetailsCmsDataBenefitImageData } from '@dm-workspace/types';

@Component({
  selector: 'dm-ncp-cms-image',
  templateUrl: './ncp-cms-image.component.html',
  styleUrls: ['./ncp-cms-image.component.scss'],
})
export class NcpCmsImageComponent {
  @Input() public data: QuoteDetailsCmsDataBenefitImageData;
}
