import { computed, inject, Injectable, signal } from '@angular/core';
import { map, of, switchMap, tap } from 'rxjs';
import { CMSSelectedMarina, LOCALIZATION_LANGUAGES, Marina, MarinaCMS } from '@dm-workspace/types';
import { CmsMarinasApiService, ObMarinasApiService } from '@dm-workspace/data-access';

@Injectable({
  providedIn: 'root',
})
export class NcpMarinasService {
  #marinasService = inject(ObMarinasApiService);
  #csMarinasApiService = inject(CmsMarinasApiService);
  marinaMap: Map<string, Marina> = new Map();
  selectedMarina = signal<undefined | CMSSelectedMarina>(undefined);
  marinaCoverUrl = computed(() => NcpMarinasService.getMarinaCoverUrl(this.selectedMarina()));
  fetchAndSelectMarina(id: string, lng: LOCALIZATION_LANGUAGES = LOCALIZATION_LANGUAGES.ENGLISH) {
    return (
      isNaN(+id) ? this.#csMarinasApiService.fetchByCode(id, lng) : this.#csMarinasApiService.fetchMarina(id, lng)
    ).pipe(
      map((value) => value.data),
      switchMap((marinaCMS: MarinaCMS) =>
        this.getObMArina(marinaCMS.attributes.code).pipe(
          map((marinaOB) => {
            if (!marinaOB) {
              throw Error('No marina match!');
            }
            return {
              marinaOB,
              marinaCMS,
            };
          })
        )
      ),
      tap((value) => this.selectedMarina.set(value))
    );
  }
  getObMArina(marinaCode: string) {
    if (this.marinaMap.has(marinaCode)) {
      return of(this.marinaMap.get(marinaCode));
    }
    return this.#marinasService.getByCode(marinaCode);
  }
  public static getMarinaCoverUrl(selectedMarina: CMSSelectedMarina | undefined) {
    const marinaCmsGallery = selectedMarina?.marinaCMS?.attributes?.contact?.image?.data?.attributes?.url;
    return marinaCmsGallery || selectedMarina?.marinaOB.imageUrl || 'assets/default-marina.jpg';
  }
}
